import { gql } from "@apollo/client"
import { Dialog as MuiDialog, DialogActions, DialogContent } from "@material-ui/core"

import { Box, Button, DatePicker, Field, Grid, Select, Typography } from "components"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"

import { ReportType } from "constants/objects/report-type"
import { compose, withFormik, withHooks, withStores } from "enhancers"
import styled from "styled-components"

const Dialog = styled(MuiDialog)`
  .MuiDialog-paperScrollPaper {
    padding: 0;
    margin: 1vw;
    min-width: 343px;
    overflow: hidden;
  }
`
const ModalContainer = styled(DialogContent)`
  min-width: 560px;
  height: 100%;
  padding: 16px;
`
const RowContainer = styled("div")`
  display: flex;
  flex-direction: row;
`
const FieldContainer = styled(Box)`
  margin: 16px 0px;
`
const ActionContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 0px 16px 16px 16px;
`
const ActionButton = styled(Button)`
  width: 100%;
`

const API = {
  GET_CLAIM_TYPE: gql`
    query GET_CLAIM_TYPE($reportType: String!) {
      claimTypesByReportType(reportType: $reportType) {
        title
      }
    }
  `,
  GET_CLAIM: gql`
    query GET_CLAIM($claimType: String!) {
      claimRequestsByClaimType(claimType: $claimType) {
        title
        name
      }
    }
  `,
  GET_COMPANY: gql`
    query {
      getEmployeeCompanies {
        companyName
      }
    }
  `,
  GET_BRAND: gql`
    query GET_BRAND($company: String!) {
      getEmployeeCompanyBranches(companyName: $company) {
        branchName
      }
    }
  `,
  GET_EMPLOYEES: gql`
    query GET_EMPLOYEES {
      getEmployees {
        employeeCode
      }
    }
  `,
}

const ReportFilterModalComponent = (props: any) => (
  <Dialog open={props.isOpen}>
    <ModalContainer>
      <Typography variant="h3">ออกรายงาน</Typography>

      <FieldContainer>
        <Typography variant="body1">ประเภทรายงาน</Typography>
        <RowContainer>
          <Field
            style={{ width: "100%" }}
            component={Select}
            label=""
            name="reportType"
            options={props.reportFormatOptions}
          />
        </RowContainer>
      </FieldContainer>

      <FieldContainer>
        <Typography variant="body1">Claim Type</Typography>
        <RowContainer>
          <Field style={{ width: "100%" }} component={Select} label="" name="claimType" options={props.claimTypes} />
        </RowContainer>
      </FieldContainer>
      <FieldContainer>
        <Typography variant="body1">Claim</Typography>
        <RowContainer>
          <Field style={{ width: "100%" }} component={Select} label="" name="claim" options={props.claim} />
        </RowContainer>
      </FieldContainer>

      <FieldContainer>
        <Grid style={{ position: "relative", marginTop: "1px" }} container spacing={8}>
          <Grid item style={{ marginTop: "1px" }} xs={6}>
            <Field
              style={{ width: "100%" }}
              component={Select}
              label="Company"
              name="company"
              options={props.company}
            />
          </Grid>
          <div style={{ position: "absolute", top: "35%", right: "294px" }}></div>
          <Grid item xs={6}>
            <Field style={{ width: "100%" }} component={Select} label="Brand" name="brand" options={props.brand} />
          </Grid>
        </Grid>
      </FieldContainer>

      <FieldContainer>
        <Grid style={{ position: "relative" }} container spacing={8}>
          <Grid item style={{ marginTop: "1px" }} xs={6}>
            <Field
              style={{ width: "100%" }}
              component={Select}
              label="Employee ID"
              name="employeeId"
              options={props.employee}
            />
          </Grid>
          <div style={{ position: "absolute", top: "35%", right: "294px" }}></div>
          <Grid item xs={6}>
            <Field style={{ width: "100%" }} component={Select} label="Status" name="status" options={props.status} />
          </Grid>
        </Grid>
      </FieldContainer>

      <FieldContainer>
        <Typography variant="body1">ช่วงวันที่ของข้อมูล</Typography>
        <Grid style={{ position: "relative", marginTop: "1px" }} container spacing={8}>
          <Grid item style={{ marginTop: "1px" }} xs={6}>
            <Field component={DatePicker} label="ตั้งแต่วันที่" name="startDate" format="dd/MM/yyyy" />
          </Grid>
          <div style={{ position: "absolute", top: "35%", right: "294px" }}>-</div>
          <Grid item xs={6}>
            <Field component={DatePicker} label="ถึงวันที่" name="endDate" format="dd/MM/yyyy" />
          </Grid>
        </Grid>
      </FieldContainer>
    </ModalContainer>
    <DialogActions>
      <ActionContainer>
        <ActionButton variant="outlined" onClick={props.handleClose} mr="16px">
          ปิดหน้าต่างนี้
        </ActionButton>
        <ActionButton disabled={props.isActionButtonDisabled} variant="contained" onClick={props.handleConfirm}>
          ยืนยัน
        </ActionButton>
      </ActionContainer>
    </DialogActions>
  </Dialog>
)

const enhancer = compose(
  withFormik({
    mapPropsToValues: (props: any) => {
      const { defaultStartDate, defaultEndDate } = props
      return {
        startDate: defaultStartDate,
        endDate: defaultEndDate,
      }
    },
  }),
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { isOpen = false, onCancel, onConfirm, defaultStartDate, defaultEndDate } = props
    const { values, setFieldValue } = props

    const { useMemo, useCallback, useEffect, useQuery, useState } = hooks

    const [claimTypes, setClaimTypes] = useState([])
    const [claim, setClaim] = useState([])
    const [company, setCompany] = useState([])
    const [brand, setBrand] = useState([])
    const [employee, setEmployee] = useState([])

    const isActionButtonDisabled = useMemo(() => !values.reportType, [values])

    const reportFormatOptions = useMemo(() => {
      const options = Object.keys(ReportType).map((key) => {
        const reportType = ReportType[key as keyof typeof ReportType]
        const label = reportType === null ? key : `${reportType}-${key}`
        const value = key
        return { label, value }
      })
      return options
    }, [])

    useQuery(API.GET_CLAIM_TYPE, {
      variables: { reportType: values.reportType },
      onCompleted: (data: any) => {
        const claimTypes = data.claimTypesByReportType

        const options = claimTypes.map((claimType: any) => ({
          label: claimType.title,
          value: claimType.title,
        }))

        setClaimTypes(options)
      },
      fetchPolicy: "network-only",
    })

    useQuery(API.GET_CLAIM, {
      variables: { claimType: values.claimType },
      onCompleted: (data: any) => {
        const claim = data.claimRequestsByClaimType

        const options = claim.map((claim: any) => ({
          label: claim.title,
          value: claim.name,
        }))

        setClaim(options)
      },
      fetchPolicy: "network-only",
    })

    useQuery(API.GET_COMPANY, {
      onCompleted: (data: any) => {
        const company = data.getEmployeeCompanies

        const options = company.map((company: any) => ({
          label: company.companyName,
          value: company.companyName,
        }))

        setCompany(options)
      },
      fetchPolicy: "network-only",
    })

    useQuery(API.GET_BRAND, {
      variables: { company: values.company },
      onCompleted: (data: any) => {
        const brand = data.getEmployeeCompanyBranches

        const options = brand.map((brand: any) => ({
          label: brand.branchName,
          value: brand.branchName,
        }))

        setBrand(options)
      },
      fetchPolicy: "network-only",
    })

    useQuery(API.GET_EMPLOYEES, {
      onCompleted: (data: any) => {
        const employee = data.getEmployees

        const options = employee.map((employee: any) => ({
          label: employee.employeeCode,
          value: employee.employeeCode,
        }))

        setEmployee(options)
      },
      fetchPolicy: "network-only",
    })

    const status = useMemo(() => {
      return Object.keys(EnumClaimRequestStatus).map((key) => {
        const value = EnumClaimRequestStatus[key as keyof typeof EnumClaimRequestStatus]
        return { label: value, value }
      })
    }, [])

    const handleClose = useCallback(() => {
      onCancel()
    }, [onCancel])

    const handleConfirm = useCallback(async () => {
      const startDate = new Date(values.startDate)
      const endDate = new Date(values.endDate)
      if (startDate > endDate) alert("วันที่เริ่มต้นต้องน้อยกว่าวันที่สิ้นสุด")
      else {
        await onConfirm(values)
        handleClose()
      }
    }, [onConfirm, handleClose, values])

    useEffect(() => {
      if (defaultStartDate) {
        setFieldValue("startDate", defaultStartDate)
      }
    }, [setFieldValue, defaultStartDate])

    useEffect(() => {
      if (defaultEndDate) {
        setFieldValue("endDate", defaultEndDate)
      }
    }, [setFieldValue, defaultEndDate])

    return {
      isOpen,
      reportFormatOptions,
      isActionButtonDisabled,
      handleClose,
      handleConfirm,
      claimTypes,
      claim,
      company,
      brand,
      employee,
      status,
    }
  }),
)

export const ReportFilterModal = enhancer(ReportFilterModalComponent)
