import { theme } from "configs"
import { env } from "configs"
import { linearGradient } from "polished"

const colors = {
  bpu: theme.APP_COLOR,
  dentsu: theme.APP_COLOR,
}

export const AppColor = colors[env.THEME as keyof typeof colors]
const colorGradient = {
  dentsu1: [`${AppColor["Primary/Primary Text"]} 100%`, `${AppColor["Error/Line"]} 0%`],
  dentsu: theme.BACKGROUND_COLOR_GRADIENT,
}
export const backGroundColorGradient = linearGradient({
  colorStops: colorGradient[env.THEME as keyof typeof colorGradient],
  toDirection: "213deg",
  fallback: AppColor["Primary/Primary Text"],
})
